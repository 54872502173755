.home {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-top-bg {
  position: relative;
  padding: 150px 0;
  background: linear-gradient(120deg, #ffffff 0%, #f5f4f9 100%);
  z-index: 0;
}
.home-top {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
.home-hero {
  padding: 0 0 100px 40px;
}
.home-hero h1 {
  font-size: 56px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 10px;
}
.home-hero p {
  font-size: 20px;
  margin-left: 10px;
  color: #b0b0b0;
  font-weight: 600;
}
.home-hero .bold {
  color: #000;
}
.home-hero a {
  background-color: #002aa2;
  color: white;
  font-size: 22px;
  padding: 15px 50px;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-block;
  margin-top: 40px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s linear;
}
.home-hero a:hover {
  background-color: #0038d2;
}
.home-gpt {
  background-color: white;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 30px 45px;
  border-radius: 16px;
  width: 100%;
  z-index: 0;
}
.home-gpt p {
  font-size: 18px;
  margin-bottom: 6px;
}
.home-gpt img {
  width: 120px;
}
.home-circle {
  position: absolute;
  z-index: -1;
  top: -500px;
  right: -400px;
}
.home-3 {
  padding: 100px 0;
}
.home-3-container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  gap: 50px;
}

.home-3-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.home-3-card img {
  width: fit-content;
}
.home-3-card h2 {
  font-size: 28px;
}
.home-3-card p {
  font-size: 18px;
  text-align: center;
}
.home-step {
  background-color: #fafafc;
}
.home-step-1,
.home-step-2 {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 120px 0 60px;
  gap: 70px;
  justify-content: space-between;
}
.home-step-1 img,
.home-step-2 img {
  width: fit-content;
  max-width: 500px;
}
.home-step-desc {
  max-width: 500px;
}

.home-step-desc h3 {
  color: #b0b0b0;
  font-size: 20px;
}
.home-step-desc h2 {
  font-size: 36px;
  margin: 24px 0;
}
.home-step-desc p {
  color: #646464;
  font-size: 18px;
}
.home-step-desc a {
  color: #002aa2;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 28px;
  display: block;
}
.home-brand p {
  background-color: #002aa2;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 120px 100px;
  font-size: 28px;
  text-align: center;
  box-sizing: border-box;
  color: white;
  font-weight: 700;
  margin-top: 70px;
}
.home-review {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 120px 0;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.home-review-box {
  background-color: white;
  border-radius: 15px;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 50px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.star {
  color: #002aa2;
  font-size: 30px;
  margin-bottom: 20px;
}
.name {
  color: #646464;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}
.review {
  color: #000;
  font-size: 22px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .home {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .home-top-bg {
    position: relative;
    padding: 100px 0;
    background: linear-gradient(120deg, #ffffff 0%, #f5f4f9 100%);
    z-index: 0;
  }
  .home-top {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }
  .home-hero {
    padding: 0 0 60px 30px;
  }
  .home-hero h1 {
    font-size: 34px;
    font-weight: 800;
    margin: 0;
    margin-bottom: 10px;
  }
  .home-hero p {
    font-size: 16px;
    margin-left: 0px;
    color: #b0b0b0;
  }
  .home-hero .bold {
    color: #000;
  }
  .home-hero a {
    background-color: #002aa2;
    color: white;
    font-size: 15px;
    padding: 10px 30px;
    border-radius: 100px;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s linear;
  }
  .home-hero a:hover {
    background-color: #0038d2;
  }
  .home-gpt {
    background-color: white;
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px 25px;
    border-radius: 8px;
    width: 100%;
    margin-left: 30px;
    z-index: 0;
  }
  .home-gpt p {
    font-size: 13px;
    margin-bottom: 6px;
  }
  .home-gpt img {
    width: 80px;
  }
  .home-circle {
    position: absolute;
    z-index: -1;
    top: -200px;
    right: -450px;
    width: 600px;
  }
  .home-3 {
    padding: 80px 0;
  }
  .home-3-container {
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 50px;
  }

  .home-3-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  .home-3-card img {
    width: 40px;
  }
  .home-3-card h2 {
    font-size: 20px;
  }
  .home-3-card p {
    font-size: 13px;
    text-align: center;
  }
  .home-step {
    background-color: #ffffff;
  }
  .home-step-1,
  .home-step-2 {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 60px 30px;
    box-sizing: border-box;
    gap: 70px;
    justify-content: space-between;
    background: linear-gradient(0deg, #ffffff, #fafafc);
  }
  .home-step-1 img,
  .home-step-2 img {
    display: none;
  }
  .home-step-desc {
    max-width: 500px;
  }

  .home-step-desc h3 {
    color: #b0b0b0;
    font-size: 13px;
  }
  .home-step-desc h2 {
    font-size: 22px;
    margin: 16px 0;
  }
  .home-step-desc p {
    color: #646464;
    font-size: 13px;
  }
  .home-step-desc a {
    color: #002aa2;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    margin-top: 16px;
    display: block;
  }
  .home-brand p {
    background-color: #002aa2;
    width: 100%;
    max-width: 1100px;
    border-radius: 0px;
    padding: 60px 50px;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    color: white;
    font-weight: 700;
    margin-top: 30px;
  }
  .home-review {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 80px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #fafafc;
    gap: 40px;
  }
  .home-review-box {
    background-color: white;
    border-radius: 15px;
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 50px 40px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .star {
    color: #002aa2;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .name {
    color: #646464;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .review {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }
}
