.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 77px;
  background-color: #ffffff;
  border-bottom: 1px solid #e7e7e7;
  font-weight: 500;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 77px;
  max-width: 1100px;
  margin: 0 auto;
}

.logo img {
  position: relative;
  height: 180px;
  left: -28px;
  top: 3px;
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-item {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  padding: 10px;
  transition: color 0.3s;
}

.nav-item:hover {
  color: #002aa2;
}
@media (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 90px;
  }
  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .logo-container {
    height: 50px;
  }
  .logo-container .logo img {
    height: 150px;
    top: -39px;
    box-sizing: border-box;
    left: 0;
    z-index: 0;
  }
  .nav-menu {
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 10px;
    z-index: 1;
  }
  .nav-item {
    padding: 4px 12px 0px;
  }
}
