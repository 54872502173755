.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 40px 40px 0 0;
  background: linear-gradient(180deg, #002aa2, #3d66e0);
  color: white;
  box-sizing: border-box;
  padding: 50px 70px 70px 70px;
}
.footer-container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
.footer .nav-item {
  color: white;
  font-weight: 600;
}
.footer p {
  margin: 0;
  font-size: 16px;
  padding: 2px 0;
  color: white;
}
.footer-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.footer-nav .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77px;
}
@media (max-width: 768px) {
  .footer {
    text-align: center;
    border-radius: 20px 20px 0 0;
    padding: 30px 30px;
  }
  .footer p {
    font-size: 12px;
    padding: 4px 10px;
    text-align: left;
  }
  .footer-nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .footer-nav .logo {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-nav .logo img {
    width: auto;
    height: 130px;
    left: 0;
  }
  .nav-menu {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .nav-item {
    font-size: 15px;
  }
}
