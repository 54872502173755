.resource-list {
  margin-bottom: 40px;
}
.resource-list-header {
  background-color: #002aa2;
  text-align: center;
  color: white;
  padding: 80px;
  border-radius: 0 0 40px 40px;
  margin-bottom: 40px;
}
.resource-list-header h1 {
  font-size: 36px;
  margin: 0;
}
.resource-list-header span {
  font-size: 20px;
  margin-top: 8px;
  display: block;
}
.resource-list-1 {
  background-color: #fafafc;
}

.resource-list .container {
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  padding: 40px 0;
}
.resource-list img {
  width: fit-content;
  height: fit-content;
}
.resource-list h2 {
  font-size: 30px;
  color: #4c4c4c;
  margin-bottom: 20px;
}
.resource-list p {
  font-size: 18px;
  color: #7e7e7e;
}
.resource-list-1,
.resource-list-2 {
  text-decoration: none;
}
.resource-list .read {
  color: #002aa2;
  text-decoration: underline;
  font-size: 16px;
}
@media (max-width: 768px) {
  .resource-list {
    margin-bottom: 40px;
  }
  .resource-list-header {
    background-color: #002aa2;
    text-align: center;
    color: white;
    padding: 50px;
    border-radius: 0 0 20px 20px;
    margin-bottom: 30px;
  }
  .resource-list-header h1 {
    font-size: 20px;
    margin: 0;
  }
  .resource-list-header span {
    font-size: 13px;
    margin-top: 8px;
    display: block;
  }
  .resource-list-1 {
    background-color: #fafafc;
  }

  .resource-list .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding: 30px 30px;
    box-sizing: border-box;
  }
  .resource-list img {
    width: 100%;
  }
  .resource-list h2 {
    font-size: 18px;
    color: #4c4c4c;
    margin-bottom: 20px;
  }
  .resource-list p {
    font-size: 13px;
    color: #7e7e7e;
  }
  .resource-list-1,
  .resource-list-2 {
    text-decoration: none;
  }
  .resource-list .read {
    color: #002aa2;
    text-decoration: underline;
    font-size: 12px;
  }
}
