.result-header {
  width: 100%;
  background-color: #fafafc;
}
.result-header h1,
p {
  padding: 0;
  margin: 0;
}
.result-header h1 {
  font-size: 35px;
  color: #4c4c4c;
}
.result-header p {
  font-size: 20px;
  color: #7e7e7e;
  margin-top: 20px;
  margin-bottom: 30px;
}
.result-gpt {
  color: #6a6a6a;
  font-size: 20px;
}
.result-contents h3,
p {
  color: #6a6a6a;
  font-size: 20px;
}
.result-contents h3 {
  padding: 28px 0;
}
.result textarea {
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 36px;
  height: 150px;
}
.result button {
  background-color: #002aa2;
  font-size: 16px;
  color: white;
  padding: 12px 40px;
  border-radius: 24px;
  width: fit-content;
  margin-top: 20px;
}
.chart-box {
  width: 100%;
  max-width: 1100px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
}
.chart {
  width: 100%;
  height: 310px;
  display: flex;
  flex-direction: row;

  align-items: center;
}
.chart-desc {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 10%;
}
.chart-desc h1 {
  font-size: 45px;
  margin-bottom: 30px;
  font-weight: 500;
}
.chart-desc h2 {
  color: #747474;
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .result-header {
    width: 100%;
    background-color: #fafafc;
  }
  .result-header h1,
  p {
    padding: 0;
    margin: 0;
  }
  .result-header h1 {
    font-size: 20px;
    color: #4c4c4c;
  }
  .result-header p {
    font-size: 13px;
    color: #7e7e7e;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .result-gpt {
    color: #6a6a6a;
    font-size: 13px;
  }
  .result-contents h3,
  p {
    color: #6a6a6a;
    font-size: 13px;
  }
  .result-contents h3 {
    padding: 28px 0;
  }
  .result textarea {
    border: 1px solid #b0b0b0;
    border-radius: 5px;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    padding: 20px;
    margin-top: 36px;
    height: 150px;
  }
  .result button {
    background-color: #002aa2;
    font-size: 13px;
    color: white;
    padding: 10px 20px;
    border-radius: 24px;
    width: fit-content;
    margin-top: 20px;
  }
  .chart-box {
    width: 100%;
    max-width: 1100px;
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .chart {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;

    align-items: center;
  }
  .chart-desc {
    width: 90%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 0;
  }
  .chart-desc h1 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
    margin-top: 30px;
  }
  .chart-desc h2 {
    color: #747474;
    font-size: 14px;
    font-weight: 400;
  }
}
