.indicator h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}
.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 70px 0;
  box-sizing: border-box;
}
.indicator-top {
  background-color: #fafafc;
  display: flex;
  flex-direction: column;
}
.indicator-top h1 {
  font-size: 36px;
  color: #4c4c4c;
}
.indicator-top p {
  font-size: 20px;
  color: #7e7e7e;
  margin-top: 20px;
}
.indicator-top input {
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
  margin: 0;
  padding: 16px;
  border: 1px solid #b0b0b0;
  box-shadow: none;
  outline: none;
  font: inherit;
  color: inherit;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.indicator-top button {
  background-color: #002aa2;
  color: white;
  font-size: 18px;
  padding: 15px 50px;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-block;
  margin-top: 40px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s linear;
  cursor: pointer;
  border: none;
}
.indicator-top button:hover {
  background-color: #0038d2;
}
.indicator-top h2 {
  font-size: 20px;
  color: #7e7e7e;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 12px;
}

.indicator-middle h1 {
  font-size: 25px;
  color: #4c4c4c;
}
.indicator-middle p {
  font-size: 18px;
  color: #7e7e7e;
  margin-top: 20px;
}

.indicator-bottom {
  background-color: #fafafc;
}
.indicator-bottom h1 {
  font-size: 25px;
  color: #4c4c4c;
}
.indicator-bottom p {
  font-size: 18px;
  color: #7e7e7e;
  margin-top: 20px;
}
.container.loading {
  position: relative;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  z-index: 10000;
}
@media (max-width: 768px) {
  .indicator h1,
  h2,
  h3,
  p {
    margin: 0;
    padding: 0;
  }
  .container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 70px 30px;
    box-sizing: border-box;
  }
  .indicator-top {
    background-color: #fafafc;
    display: flex;
    flex-direction: column;
  }
  .indicator-top h1 {
    font-size: 20px;
    color: #4c4c4c;
  }
  .indicator-top p {
    font-size: 13px;
    color: #7e7e7e;
    margin-top: 20px;
  }
  .indicator-top input {
    box-sizing: border-box;
    font-size: 13px;
    width: 100%;
    border-radius: 4px;
    margin: 0;
    padding: 12px;
    border: 1px solid #b0b0b0;
    box-shadow: none;
    outline: none;
    font: inherit;
    color: inherit;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .indicator-top button {
    background-color: #002aa2;
    color: white;
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 100px;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s linear;
    cursor: pointer;
    border: none;
  }
  .indicator-top button:hover {
    background-color: #0038d2;
  }
  .indicator-top h2 {
    font-size: 15px;
    color: #7e7e7e;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 12px;
  }

  .indicator-middle h1 {
    font-size: 18px;
    color: #4c4c4c;
  }
  .indicator-middle p {
    font-size: 13px;
    color: #7e7e7e;
    margin-top: 20px;
  }

  .indicator-bottom {
    background-color: #fafafc;
  }
  .indicator-bottom h1 {
    font-size: 18px;
    color: #4c4c4c;
  }
  .indicator-bottom p {
    font-size: 13px;
    color: #7e7e7e;
    margin-top: 20px;
  }
  .container.loading {
    position: relative;
  }

  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .loader {
    z-index: 10000;
  }
}
