.DynamicWidthBar {
  display: flex;
  width: 100%;
  border-radius: 30px;
  background-color: rgb(241, 243, 244);
  margin-top: 10px;
  margin-bottom: 20px;
}

.bar {
  height: 24px;
  background-color: blue;
  transition: width 0.3s ease;
  border-radius: 30px;
}
@media (max-width: 768px) {
  .DynamicWidthBar {
    display: flex;
    width: 100%;
    border-radius: 30px;
    background-color: rgb(241, 243, 244);
    margin-top: 6px;
    margin-bottom: 8px;
  }

  .bar {
    height: 16px;
    background-color: blue;
    transition: width 0.3s ease;
    border-radius: 30px;
  }
}
