.about {
  background-color: #fafafc;
}
.about h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}
.about h1 {
  font-size: 36px;
  color: #4c4c4c;
}
.about p {
  font-size: 20px;
  color: #7e7e7e;
  margin-top: 20px;
}
.about h2 {
  font-size: 25px;
  color: #4c4c4c;
  margin-top: 50px;
}
.about-profile {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 40px;
}
.profile-img {
  width: 160px;
}
.about-profile h3 {
  font-size: 25px;
  color: #4c4c4c;
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .about {
    padding: 0 0px;
  }
  .about h1 {
    font-size: 20px;
    text-align: left;
  }
  .about p {
    text-align: left;
    font-size: 13px;
  }
  .about h2 {
    font-size: 18px;
    text-align: left;
  }
  .about-profile {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: start;
    text-align: center;
    gap: 20px;
    margin-bottom: 0px;
  }
  .about-profile h3 {
    font-size: 15px;
    text-align: left;
    margin-bottom: 12px;
  }
  .about-profile div {
    display: flex;
    flex-direction: column;
  }
  .about-profile img {
    width: 25%;
    height: auto;
  }
  .about .container {
    text-align: center;
  }
}
