.resource h1 {
  font-size: 40px;
  color: #4c4c4c;
}
.resource a {
  text-decoration: none;
  color: #4c4c4c;
}
.resource p {
  font-size: 20px;
  color: #7e7e7e;
}
.resource-btn {
  margin-top: 40px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #7e7e7e;
  padding: 12px 20px;
  box-sizing: border-box;
  display: block;
  width: fit-content;
}
.resource-contents h2 {
  font-size: 20px;
  color: #7e7e7e;
  font-weight: 500;
  padding: 30px 0 10px 0;
}
.resource-contents p {
  padding: 10px 0;
}
.resource-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.resource-contents img {
  width: fit-content;
  margin: 50px auto;
}
@media (max-width: 768px) {
  .resource .container {
    padding: 40px 30px 60px;
  }
  .resource h1 {
    font-size: 20px;
    color: #4c4c4c;
  }
  .resource a {
    text-decoration: none;
    color: #4c4c4c;
    font-size: 14px;
  }
  .resource p {
    font-size: 13px;
    color: #7e7e7e;
  }
  .resource-btn {
    margin-top: 20px;
    font-size: 13px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #7e7e7e;
    padding: 10px 15px;
    box-sizing: border-box;
    display: block;
    width: fit-content;
  }
  .resource-contents h2 {
    font-size: 16px;
    color: #7e7e7e;
    font-weight: 500;
    padding: 30px 0 10px 0;
  }
  .resource-contents p {
    padding: 6px 0;
  }
  .resource-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .resource-contents img {
    width: 90%;
    margin: 50px auto;
  }
}
